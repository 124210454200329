import React, { useState } from 'react';
import AddDepartmentHeadForm from './AddDepartmentHeadForm';
import { useUsersPage } from '../../../store/UsersProvider';
import { IDepartmentHeadForm } from '../core/models';
import clsx from 'clsx';

export default function UserListItem({ user }: any) {
  const { id, name, email, phoneNumber, department } = user;
  const data: IDepartmentHeadForm = {
    id,
    name,
    email,
    phoneNumber: phoneNumber.slice(4),
    department: department.join(','),
  };
  const { openCollapse, handleToggle, setDepartmentHead } = useUsersPage();
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div style={{ flexBasis: '40%' }}>
          <span className="card-title">{user.email}</span>
        </div>
        <div className="align-self-center" style={{ flexBasis: '25%' }}>
          <span className="card-title">{user.department.join(', ')}</span>
        </div>
        <div className="align-self-center" style={{ flexBasis: '15%' }}>
          <h6
            className={clsx('card-title dhc_account_state', {
              dhc_account_state_active: user.active,
              dhc_account_state_pending: !user.active,
            })}
          >
            {user.active ? 'Active' : 'Pending'}
          </h6>
        </div>
        <div className="align-self-center" style={{ flexBasis: '20%' }}>
          <span
            onClick={() => {
              setDepartmentHead({ action: 'edit', data });
              handleToggle(`edit-${id}`);
            }}
            aria-expanded={openCollapse === `edit-${id}`}
          >
            <i className="fa-solid fa-pencil fs-4 me-4"></i>
          </span>

          <i
            className={clsx('fa-regular fa-envelope fs-4 me-4', {
              dhc_email_inactive: user.active,
              dhc_icon_color: !user.active,
            })}
            data-tooltip-id="tooltip"
            data-tooltip-content="resend invite"
            onClick={() => {
              if (!user.active) {
                //   mutation.mutate({ email: user.email });
              }
            }}
          ></i>
          <span
            onClick={() => handleToggle(`delete-${id}`)}
            aria-expanded={openCollapse === `delete-${id}`}
          >
            <i className="fa-solid fa-trash fs-4 "></i>
          </span>
        </div>
      </div>
      <div
        className={`collapse ${openCollapse === `edit-${id}` ? 'show' : ''}`}
      >
        <AddDepartmentHeadForm />
      </div>
      <div
        className={`collapse ${openCollapse === `delete-${id}` ? 'show' : ''}`}
      >
        <div>Are you sure you want to delete</div>
      </div>
    </div>
  );
}
