/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../helpers/AssetHelpers';
interface Props {
  goToPage?: string;
}
export default function ErrorPage({ goToPage }: Props) {
  return (
    <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
      <div className="text-center">
        <a href="/homepage" className="mb-10 pt-lg-20">
          <img
            alt="Logo"
            src={toAbsoluteUrl('/media/logos/favicon.svg')}
            className="mb-5"
            style={{ height: '50px' }}
          />
        </a>
        <h2 className="d-flex justify-content-center align-items-center gap-2 mb-4">
          <span className="display-1 fw-bold">4</span>
          <i className="bi bi-exclamation-circle-fill text-danger display-4"></i>
          <span className="display-1 fw-bold bsb-flip-h">4</span>
        </h2>
        <h3 className="h2 mb-2">Oops! You're lost.</h3>
        <p className="mb-5">The page you are looking for was not found.</p>
        <Link to={goToPage ? goToPage : '/auth'}>
          <a className="btn bsb-btn-5xl btn-dark rounded-pill px-5 fs-6 m-0" role="button">
            Back to Home
          </a>
        </Link>
      </div>
    </div>
  );
}
