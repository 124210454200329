/* eslint-disable jsx-a11y/anchor-is-valid */
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
} from 'mui-datatables';
import CreateEditReport from '../components/CreateEditReport';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import LoadingSpinner from '../../sharedcomponents/LoadingSpinner';
import { getReportData } from '../../../apis/app.service';

import { IReportForm } from '../core/models';
import { useReportsPage } from '../../../store/ReportsProvider';
import { formatDate } from '../../../helpers/FormatDate';
import ConfirmReportDelete from './ConfirmReportDelete';
import PdfViewer from './pdfviewer';
import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useAuth } from '../../../store/AuthProvider';

const ReportsWrapper = () => {
  const { setReport, setReportUrl } = useReportsPage();
  const { user } = useAuth();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['reports'],
    queryFn: () => getReportData({ admin_Account: user!._id }),
  });

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'year',
      label: 'Year',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'period',
      label: 'Period',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          return (
            <>
              <div>
                <span className="fw-bold">
                  {value ? moment(value[0]).format('DD/MMM/YYYY') : 'no data'}
                </span>
                {' to '}
                <span className="fw-bold">
                  {value ? moment(value[1]).format('DD/MMM/YYYY') : ''}
                </span>
              </div>
            </>
          );
        },
      },
    },

    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const row = tableMeta.tableData[tableMeta.rowIndex];
          const { _id, name, type, recipientIds, period, download_link } = row;
          const startDate = formatDate(period[0]);
          const endDate = formatDate(period[1]);
          const data: IReportForm = {
            id: _id,
            name,
            type,
            recipientIds,
            startDate,
            endDate,
            report: '',
          };

          return (
            <>
              {/* begin::Menu */}
              <div
                className="d-flex flex-row align-content-center w-125px py-2"
                data-kt-menu="true"
              >
                <span
                  className="btn-icon-default pe-5 icon-color"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_preview_report"
                  onClick={() => setReportUrl(download_link)}
                >
                  <i
                    className="fas fa-eye btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="preview"
                  ></i>
                </span>
                {/* begin::Menu item */}
                <span
                  className="btn-icon-default pe-5 icon-color"
                  onClick={() => {
                    setReport({
                      action: 'edit',
                      data,
                    });
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_report"
                >
                  <i
                    className="fas fa-edit btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Edit"
                  ></i>
                </span>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <span
                  className="btn-icon-default pe-5 icon-color"
                  onClick={() => {
                    setReport({
                      action: 'delete',
                      data,
                    });
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_confirm_delete"
                >
                  <i
                    className="fas fa-trash btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Delete"
                  ></i>
                </span>
                {/* end::Menu item */}
                <span
                  className="btn-icon-default icon-color"
                  onClick={() =>
                    downloadFile(
                      tableMeta.tableData[tableMeta.rowIndex].download_link,
                      tableMeta.tableData[tableMeta.rowIndex].name
                    )
                  }
                >
                  <i
                    className="fas fa-download btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Download"
                  ></i>
                </span>
                {/* end::Menu item */}
              </div>
              {/* end::Menu */}
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    viewColumns: false,
    responsive: 'standard',
    jumpToPage: true,
    downloadOptions: { filename: 'sema_reports.csv', separator: ',' },
    elevation: 1,
    customToolbar: () => {
      return (
        <AppButton
          btnType="success"
          data-bs-toggle="modal"
          width="140px"
          data-bs-target="#kt_modal_create_report"
          onClick={() =>
            setReport({
              action: 'create',
              data: {
                name: '',
                type: 'monthly',
                recipientIds: [],
                startDate: '',
                endDate: '',
                report: '',
              },
            })
          }
        >
          <i className="fa-solid fa-plus me-2"></i>
          <span>Add Report</span>
        </AppButton>
      );
    },
    customSearchRender: (searchText, handleSearch) => {
      return (
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <input
              type="text"
              data-kt-report-table-filter="search"
              className="form-control form-control-solid ps-14 mt-3"
              style={{ width: '250px' }}
              placeholder="Search Report"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
      );
    },
  };

  const downloadFile = (downloadLink: string, name: string) => {
    fetch(downloadLink, {
      method: 'GET',
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${name}.pdf`;
        a.click();
      });
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <div className="w-75 mr-auto">
        <p>Oops! Something Went Wrong! Try Again!!!</p>
        <p>{error.message}</p>
      </div>
    );
  }

  return (
    <>
      <MUIDataTable
        title={'Reports'}
        data={data}
        columns={columns}
        options={options}
      />

      <CreateEditReport />
      <ConfirmReportDelete />
      <PdfViewer />
    </>
  );
};

export default ReportsWrapper;
