import React from 'react';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { deleteAccount } from '../../../../../apis/app.service';
import { useAuth } from '../../../../../store/AuthProvider';
import AppButton from '../../../../sharedcomponents/Buttons/AppButton/AppButton';
import CloseButton from '../../../../sharedcomponents/Buttons/IconButton/CloseButton';

export default function DeleteAccount() {
  const { logout } = useAuth();
  const mutation = useMutation({
    mutationFn: deleteAccount,
    onSuccess: (res) => {
      const { message } = res;

      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
      logout();
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });
  return (
    <div>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Account</h3>
          <p>Delete my account</p>

          <div className="card-footer text-md-end mt-4">
            <AppButton
              btnType="danger"
              data-bs-toggle="modal"
              data-bs-target="#deleteAccount"
            >
              Delete
            </AppButton>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="deleteAccount"
        aria-labelledby="deleteAccount"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Delete Account
              </h1>
              <CloseButton data-bs-dismiss="modal" />
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to delete your account? This action cannot
                be reversed.
              </p>
            </div>
            <div className="modal-footer">
              <AppButton
                type="button"
                btnType="secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </AppButton>
              <AppButton
                type="button"
                btnType="danger"
                data-bs-dismiss="modal"
                onClick={() => mutation.mutate()}
              >
                Delete
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
