/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from '@tanstack/react-query';
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
} from 'mui-datatables';
import CreateEditInterview from '../components/CreateEditInterview';

import LoadingSpinner from '../../sharedcomponents/LoadingSpinner';

import { getInterviews } from '../../../apis/app.service';
import moment from 'moment';

import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useInterviewsPage } from '../../../store/InterviewsProvider';
import ConfirmInterviewDelete from './ConfirmInterviewDelete';

export default function InterviewsPage() {
  const { setInterview } = useInterviewsPage();

  const columns = [
    {
      name: 'name',
      label: 'Report Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'month',
      label: 'Month',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'year',
      label: 'Year',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'period',
      label: 'Period',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          return (
            <>
              <div>
                <span className="fw-bold">
                  {value ? moment(value[0]).format('DD/MMM/YYYY') : 'no data'}
                </span>
                {' to '}
                <span className="fw-bold">
                  {value ? moment(value[1]).format('DD/MMM/YYYY') : ''}
                </span>
              </div>
            </>
          );
        },
      },
    },
    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          let row = tableMeta.tableData[tableMeta.rowIndex];

          const data = {
            id: row._id,
            name: `${row.name}`,
            duration: `${row.month}-${row.year}`,
            interview: '',
          };
          return (
            <>
              {/* begin::Menu */}
              <div
                className="d-flex flex-row align-content-center w-125px py-2"
                data-kt-menu="true"
              >
                {/* begin::Menu item */}
                <span
                  className="btn-icon-default pe-5 icon-color"
                  onClick={() => {
                    setInterview({ action: 'edit', data });
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_interview"
                >
                  <i
                    className="fas fa-edit btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Edit"
                  ></i>
                </span>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <span
                  className="btn-icon-default pe-5 icon-color"
                  onClick={() => {
                    setInterview({ action: 'edit', data });
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_confirm_delete"
                >
                  <i
                    className="fas fa-trash btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Delete"
                  ></i>
                </span>
                {/* end::Menu item */}
                <span
                  className="btn-icon-default icon-color"
                  onClick={() => downloadFile(row.download_link, row.name)}
                >
                  <i
                    className="fas fa-download btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Download"
                  ></i>
                </span>
                {/* end::Menu item */}
              </div>
              {/* end::Menu */}
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    viewColumns: false,
    responsive: 'standard',
    jumpToPage: true,
    downloadOptions: { filename: 'sema_reports.csv', separator: ',' },
    elevation: 1,
    customToolbar: () => {
      return (
        <AppButton
          type="button"
          btnType="success"
          width="160px"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_create_interview"
          onClick={() =>
            setInterview({
              action: 'create',
              data: {
                name: '',
                duration: '',
                interview: '',
              },
            })
          }
        >
          <i className="fa-solid fa-plus fa-lg me-2"></i>
          <span>Add Interview</span>
        </AppButton>
      );
    },
    customSearchRender: (searchText, handleSearch) => {
      return (
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <input
              type="text"
              data-kt-interview-table-filter="search"
              className="form-control form-control-solid ps-14 mt-3"
              style={{ width: '250px' }}
              placeholder="Search Interview"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
      );
    },
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['interviews'],
    queryFn: getInterviews,
  });

  const downloadFile = (downloadLink: string, name: string) => {
    fetch(downloadLink, {
      method: 'GET',
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${name}.csv`;
        a.click();
      });
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <div className="d-flex justify-center align-content-center">
        <p>Oops! Something Went Wrong! Try Again!!!</p>
        <p>{error.message}</p>
      </div>
    );
  }

  return (
    <>
      <MUIDataTable
        title={'Interviews'}
        data={data}
        columns={columns}
        options={options}
      />
      <CreateEditInterview />

      <ConfirmInterviewDelete />
    </>
  );
}
