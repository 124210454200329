import React from 'react';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';

import SignInPage from './components/SignInPage';
import SignUpPage from './components/SignUpPage';
import './core/style.scss';

function AuthLayout() {
  return (
    <div className="Auth_container d-flex justify-content-center align-items-center">
      <Outlet />
    </div>
  );
}

export default function AuthPage() {
  return (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route path="signin" element={<SignInPage />} />
        <Route path="signup" element={<SignUpPage />} />
        <Route index element={<Navigate to="signin" />} />
      </Route>
    </Routes>
  );
}
