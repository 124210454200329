import toast from 'react-hot-toast';
import Select from 'react-select';
import { Formik, Form, FormikValues, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import {
  SCreateDevice,
  SGetUsers,
  SUpdateDevice,
} from '../../../apis/app.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useAuth } from '../../../store/AuthProvider';
import { IDevice, IDeviceForm, IDevicePayload } from '../core/models';
import { useDevicesPage } from '../../../store/DevicesProvider';

const deviceSchema = Yup.object({
  category: Yup.string().required().label('Device category'),
  gen: Yup.string().required().label('Device generation'),
  device_id: Yup.string().required().label('Device Id'),
  user_Id: Yup.array()
    .min(1, 'Assigned institution is required')
    .required('Assigned institution is required')
    .label('Assigned institution'),
  placement: Yup.string().required().label('Device placement'),
  device_imei: Yup.string().required().label('Device imei'),
  deploymentDate: Yup.string().required().label('Device deployment date'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{9}$/, 'Phone number must be exactly 9 digits')
    .required()
    .label('Phone'),
  active: Yup.string().required().label('Active'),
});

export default function CreateEditDevice() {
  const { device, setDevice } = useDevicesPage();
  const { action, data } = device;
  const { user } = useAuth();

  const queryClient = useQueryClient();

  const { data: externalUsers } = useQuery({
    queryKey: ['users', 'devices'],
    queryFn: () =>
      SGetUsers(user!._id, {
        role: 'institution_head',
        admin_Account: user!._id,
      }),
  });

  const mutation = useMutation({
    mutationFn: SCreateDevice,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['devices'],
      });
      const { message } = data;

      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const mutationUpdate = useMutation({
    mutationFn: SUpdateDevice,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['devices'],
      });
      const { message } = data;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const submitForm = async (values: IDeviceForm, actions: FormikValues) => {
    const payload: IDevicePayload = {
      ...values,
      phoneNumber: `+256${values.phoneNumber}`,
    };

    if (action === 'create') {
      payload.admin_Account = user!._id;
      payload.created_By = user!._id;
      mutation.mutate(payload);
    } else if (action === 'edit') {
      mutationUpdate.mutate({ deviceId: values.id!, body: payload });
    }
  };

  return (
    <div className="modal fade" id="kt_modal_create_device" aria-hidden="true">
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: '900px' }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2>
              <span className="text-capitalize">{action}</span> Device
            </h2>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            {/* begin body */}
            <div className="flex-row-fluid ">
              <Formik
                validationSchema={deviceSchema}
                initialValues={data}
                onSubmit={submitForm}
                enableReinitialize={true}
              >
                {(formik) => (
                  <Form className="form" noValidate>
                    <div className="w-100 mb-3 d-flex flex-column justify--center">
                      <div className="w-100">
                        <div className="mb-3 row w-50 id-item">
                          <label className="form-label fs-5 col-5 id-item-label">
                            <span className="required">Device Type:</span>
                            <i
                              className="fas fa-exclamation-circle ms-1"
                              style={{ fontSize: '0.8rem' }}
                              data-bs-toggle="tooltip"
                              title="Whether device is deployed or for test"
                            ></i>
                          </label>
                          <div className="col-7 row id-item-values">
                            <div className="form-check col-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="category"
                                value="field"
                                disabled={action === 'edit'}
                                checked={formik.values.category === 'field'}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'category',
                                    e.target.value
                                  );
                                  setDevice((device: IDevice) => ({
                                    ...device,
                                    data: { ...device.data, category: 'field' },
                                  }));
                                }}
                              />
                              <label className="form-check-label">Field</label>
                            </div>
                            <div className="form-check col-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="category"
                                value="test"
                                disabled={action === 'edit'}
                                checked={formik.values.category === 'test'}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'category',
                                    e.target.value
                                  );
                                  setDevice((device: IDevice) => ({
                                    ...device,
                                    data: { ...device.data, category: 'test' },
                                  }));
                                }}
                              />
                              <label className="form-check-label">Test</label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row w-50 id-item">
                          <label className="form-label fs-5 col-5 id-item-label">
                            <span className="required">Device Gen:</span>
                            <i
                              className="fas fa-exclamation-circle ms-1"
                              style={{ fontSize: '0.8rem' }}
                              data-bs-toggle="tooltip"
                              title="Version of chip"
                            ></i>
                          </label>
                          <div className="col-7 row id-item-values">
                            <div className="form-check col-4">
                              <input
                                className="form-check-input col-4"
                                type="radio"
                                name="gen"
                                value="4"
                                checked={formik.values.gen === '4'}
                                onChange={(e) => {
                                  formik.setFieldValue('gen', e.target.value);
                                  setDevice((device: IDevice) => ({
                                    ...device,
                                    data: { ...device.data, gen: '4' },
                                  }));
                                }}
                              />
                              <label className="form-check-label">4</label>
                            </div>
                            <div className="form-check col-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gen"
                                value="5"
                                checked={formik.values.gen === '5'}
                                onChange={(e) => {
                                  formik.setFieldValue('gen', e.target.value);
                                  setDevice((device: IDevice) => ({
                                    ...device,
                                    data: { ...device.data, gen: '5' },
                                  }));
                                }}
                              />
                              <label className="form-check-label">5</label>
                            </div>
                            <div className="form-check col-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gen"
                                value="6"
                                checked={formik.values.gen === '6'}
                                onChange={(e) => {
                                  formik.setFieldValue('gen', e.target.value);
                                  setDevice((device: IDevice) => ({
                                    ...device,
                                    data: { ...device.data, gen: '6' },
                                  }));
                                }}
                              />
                              <label className="form-check-label">6</label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row w-50 d-flex align-items-center id-item">
                          <label className="form-label fs-5 col-5 id-item-label">
                            <span className="required">Device ID:</span>
                            <i
                              className="fas fa-exclamation-circle ms-1"
                              style={{ fontSize: '0.8rem' }}
                              data-bs-toggle="tooltip"
                              title="Unique ID assigned to device in firmware"
                            ></i>
                          </label>
                          <div className="col-7 row id-item-values">
                            <input
                              type="text"
                              className="form-control w-50"
                              value={formik.values.device_id}
                              disabled
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="row mb-3">
                          <div className="col-md-6 form-group">
                            <label className="form-label fs-5">
                              <span className="required">
                                Assigned Institution
                              </span>
                              <i
                                className="fas fa-exclamation-circle ms-1"
                                style={{ fontSize: '0.8rem' }}
                                data-bs-toggle="tooltip"
                                title="Institution to which device data is linked"
                              ></i>
                            </label>
                            <div>
                              <Select
                                name="type"
                                onChange={(option: {
                                  value: string;
                                  label: string;
                                }) => {
                                  formik.setFieldValue('user_Id', [
                                    option.value,
                                  ]);
                                }}
                                value={externalUsers
                                  ?.filter((item: any) =>
                                    formik.values.user_Id.includes(item._id)
                                  )
                                  .map((item: any) => ({
                                    value: item._id,
                                    label: item.name,
                                  }))}
                                options={externalUsers?.map((item: any) => {
                                  return {
                                    value: item._id,
                                    label: item.name,
                                  };
                                })}
                              />
                            </div>
                            <div className="text-danger">
                              <ErrorMessage name="user_Id" />
                            </div>
                          </div>
                          <div className="col-md-6 form-group">
                            <label className="form-label fs-5">
                              <span className="required">Placement</span>
                              <i
                                className="fas fa-exclamation-circle ms-1"
                                style={{ fontSize: '0.8rem' }}
                                data-bs-toggle="tooltip"
                                title="Where / department device is deployed at a given office"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              className="form-control"
                              name="placement"
                              placeholder="E.g Exit"
                            />
                            <div className="text-danger">
                              <ErrorMessage name="placement" />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 form-group">
                            <label className="form-label fs-5">
                              <span className="required">Imei</span>
                              <i
                                className="fas fa-exclamation-circle ms-1"
                                style={{ fontSize: '0.8rem' }}
                                data-bs-toggle="tooltip"
                                title="Imea on the chip communication module"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              className="form-control"
                              name="device_imei"
                              placeholder=""
                            />
                            <div className="text-danger">
                              <ErrorMessage name="device_imei" />
                            </div>
                          </div>
                          <div className="col-md-6 form-group">
                            <label
                              htmlFor="phoneNumber"
                              className="form-label fs-5"
                            >
                              <span className="required">Phone</span>
                              <i
                                className="fas fa-exclamation-circle ms-1"
                                style={{ fontSize: '0.8rem' }}
                                data-bs-toggle="tooltip"
                                title="Phone number used in chip"
                              ></i>
                            </label>

                            <div className="input-group">
                              <span className="input-group-text">+256</span>

                              <input
                                type="text"
                                name="phoneNumber"
                                maxLength={9}
                                className="form-control"
                                placeholder="E.g 780000000"
                                aria-label="Phone"
                                onChange={formik.handleChange}
                                value={formik.values.phoneNumber}
                              />
                            </div>
                            <div className="text-danger">
                              <ErrorMessage name="phoneNumber" />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label className="form-label fs-5">
                              <span className="required">Deployment Date</span>
                              <i
                                className="fas fa-exclamation-circle ms-1"
                                style={{ fontSize: '0.8rem' }}
                                data-bs-toggle="tooltip"
                                title="Deployment date"
                              ></i>
                            </label>

                            <DatePicker
                              value={
                                formik.values.deploymentDate
                                  ? dayjs(
                                      formik.values.deploymentDate,
                                      'DD/MM/YYYY'
                                    )
                                  : null
                              }
                              size="large"
                              format={'DD/MM/YYYY'}
                              style={{ width: '100%' }}
                              placement="bottomLeft"
                              onChange={(date: any, dateString: any) =>
                                formik.setFieldValue(
                                  'deploymentDate',
                                  dateString
                                )
                              }
                            />

                            <div className="text-danger">
                              <ErrorMessage name="deploymentDate" />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label className="form label fs-5">
                              <span className="required">Device Active</span>
                              <i
                                className="fas fa-exclamation-circle ms-1"
                                style={{ fontSize: '0.8rem' }}
                                data-bs-toggle="tooltip"
                                title="Switch device on and off"
                              ></i>
                            </label>
                            <div className="form-check form-check-solid form-switch">
                              <input
                                className="form-check-input"
                                style={{
                                  height: '25px',
                                  width: '55px',
                                  marginTop: '15px',
                                }}
                                type="checkbox"
                                checked={formik.values.active}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'active',
                                    e.target.checked
                                  );
                                }}
                              />
                            </div>

                            <div className="text-danger">
                              <ErrorMessage name="active" />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col form-group">
                            <label className="form-label fs-5">
                              <span>State</span>
                              <i
                                className="fas fa-exclamation-circle ms-1"
                                style={{ fontSize: '0.8rem' }}
                                data-bs-toggle="tooltip"
                                title="Device current status"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              as="textarea"
                              className="form-control"
                              name="state"
                              placeholder="E.g Chip is broken / Device functional"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mx-auto">
                        <AppButton btnType="success" type="submit">
                          Save
                        </AppButton>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
