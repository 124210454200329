/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../store/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import './css/sidebar.css';
import { toAbsoluteUrl } from '../../../helpers/AssetHelpers';
import { Dropdown, MenuProps } from 'antd';

export default function SideBar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('users');
  const { token, logout, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const items: MenuProps['items'] = [
    {
      label: (
        <Link to="/settings/profile">
          <span style={{ fontSize: 16 }}>profile</span>
        </Link>
      ),
      key: 'profile',
      icon: <UserOutlined style={{ fontSize: 18 }} />,
    },

    {
      label: <hr className="m-1" />,
      disabled: true,
      key: 'divider',
    },

    {
      label: (
        <>
          <div
            onClick={() => {
              logout();
            }}
          >
            <span style={{ fontSize: 16 }}>Logout</span>
          </div>
        </>
      ),
      key: 'logout',
      icon: <i className="bx bx-log-out nav_icon" style={{ fontSize: 18 }}></i>,
    },
  ];

  useEffect(() => {
    if (!token) {
      navigate('/auth', { replace: true });
    }
  }, [token]);

  useEffect(() => {
    const page = location.pathname.split('/')[1];
    setActiveLink(page);
  }, [location]);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLinkClick = (linkName: string) => {
    setActiveLink(linkName);
  };

  return (
    <div className="Sidebar_body">
      <div className={`sidebar-container ${isNavOpen ? 'l-navbar-show' : ''}`}>
        <header className={`header ${isNavOpen ? 'body-pd' : ''}`} id="header">
          <div className="header_toggle" onClick={toggleNav}>
            <i
              className={`bx ${isNavOpen ? 'bx-x' : 'bx-menu'}`}
              id="header-toggle"
            ></i>
          </div>
          <Dropdown menu={{ items }} placement="bottomLeft" trigger={['click']}>
            <div className="header_img">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe0Hsu8YYt0Gi6ZxY-aPkV7Os7abmxProPNg&s"
                alt=""
              />
            </div>
          </Dropdown>
        </header>
        <div className={`l-navbar ${isNavOpen ? 'nav-open' : ''}`} id="nav-bar">
          <nav className="nav">
            <div>
              <a className="nav_logo">
                {/* <i className="bx bx-layer nav_logo-icon"></i> */}
                <img
                  alt="Logo"
                  src={toAbsoluteUrl('/media/logos/sema-logo-white.svg')}
                  className="d-inline"
                  style={{ height: '25px' }}
                />
                <span className="nav_logo-name">SEMA</span>
              </a>
              <div className="nav_list">
                <Link to="/users">
                  <a
                    className={`nav_link ${
                      activeLink === 'users' ? 'active' : ''
                    }`}
                    onClick={() => handleLinkClick('users')}
                  >
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl('/media/icons/user.svg')}
                      className="d-inline"
                      style={{ height: '20px' }}
                    />
                    <span className="nav_name">Users</span>
                  </a>
                </Link>
                <Link to="/interviews">
                  <a
                    className={`nav_link ${
                      activeLink === 'interviews' ? 'active' : ''
                    }`}
                    onClick={() => handleLinkClick('interviews')}
                  >
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl('/media/icons/interviews.svg')}
                      className="d-inline"
                      style={{ height: '20px' }}
                    />
                    <span className="nav_name">Interviews</span>
                  </a>
                </Link>

                {user?.role === 'sema_admin' ? (
                  <Link to="/devices">
                    <a
                      className={`nav_link ${
                        activeLink === 'devices' ? 'active' : ''
                      }`}
                      onClick={() => handleLinkClick('devices')}
                    >
                      <i className="bx bxs-devices text-white fs-4"></i>
                      <span className="nav_name">Devices</span>
                    </a>
                  </Link>
                ) : null}

                <Link to="/reports">
                  <a
                    className={`nav_link ${
                      activeLink === 'reports' ? 'active' : ''
                    }`}
                    onClick={() => handleLinkClick('reports')}
                  >
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl('/media/icons/reports.svg')}
                      className="d-inline"
                      style={{ height: '20px' }}
                    />
                    <span className="nav_name">Reports</span>
                  </a>
                </Link>
              </div>
            </div>
            <a
              className="nav_link"
              onClick={() => {
                logout();
              }}
            >
              <i className="bx bx-log-out nav_icon"></i>{' '}
              <span className="nav_name">SignOut</span>
            </a>
          </nav>
        </div>
        <div className="height-100 bg-light">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
