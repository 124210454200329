import { ReportsProvider } from '../../store/ReportsProvider';
import ReportsWrapper from './components/ReportsWrapper';

import './core/style.scss';

const ReportsPage = () => {
  return (
    <ReportsProvider>
      <ReportsWrapper />
    </ReportsProvider>
  );
};

export default ReportsPage;
