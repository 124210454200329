import { InterviewsProvider } from '../../store/InterviewsProvider';
import InterviewsWrapper from './components/InterviewsWrapper';

import './core/style.scss';

const InterviewsPage = () => {
  return (
    <InterviewsProvider>
      <InterviewsWrapper />
    </InterviewsProvider>
  );
};

export default InterviewsPage;
