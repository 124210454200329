/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, FormikValues, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { useMutation } from '@tanstack/react-query';
import { SignUpForm } from '../core/models';
import { SignUp } from '../../../apis/app.service';
import { useAuth } from '../../../store/AuthProvider';
import { User } from '../../../store/core/models';

const AuthSchema = Yup.object({
  name: Yup.string().required().label('Name'),
  email: Yup.string().email().required().label('Email'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{9}$/, 'Phone number must be exactly 9 digits')
    .required()
    .label('Phone'),
  password: Yup.string().required().label('Password'),
  confirmPassword: Yup.string().required().label('Password confirm'),
});

const initialValues = {
  name: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  type: 'internal',
  role: 'private_admin',
};

export default function SignUpPage() {
  const { token, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/users', { replace: true });
    }
  }, [navigate, token]);
  const mutation = useMutation({
    mutationFn: SignUp,
    onSuccess: (res) => {
      const { message, data } = res;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });

      const { _id, email, phoneNumber, name, role } = data.user;
      const [firstName, lastName] = name.split(' ');

      const user: User = {
        _id,
        firstName,
        lastName: lastName || null,
        email,
        phoneNumber: phoneNumber.slice(4),
        role,
      };

      login(data.token, user);
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });
  const submitForm = async (values: SignUpForm, actions: FormikValues) => {
    if (values.password !== values.confirmPassword) {
      toast.error('Passwords do not match', {
        duration: 3000,
        position: 'top-right',
      });
      return;
    }
    const payload = { ...values, phoneNumber: `+256${values.phoneNumber}` };

    mutation.mutate(payload);
  };
  return (
    <div className="card Auth_card">
      <h3 className="text-center">Sign up to create your account</h3>
      <div className="card-body ">
        <Formik
          validationSchema={AuthSchema}
          initialValues={initialValues}
          onSubmit={submitForm}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form className="form" noValidate>
              <div className="">
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="E.g John Kitya"
                    aria-describedby="name"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Email address</label>
                  <input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    placeholder="E.g johnKitya@yopmail.com"
                    className="form-control"
                    aria-describedby="email"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="email" />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Phone</label>
                  <div className="input-group">
                    <span className="input-group-text">+256</span>

                    <input
                      type="text"
                      name="phoneNumber"
                      maxLength={9}
                      className="form-control"
                      placeholder="E.g 780000000"
                      aria-label="Phone"
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                    />
                  </div>
                  <div className="text-danger">
                    <ErrorMessage name="phoneNumber" />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    className="form-control"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="password" />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Confirm Password</label>
                  <input
                    name="confirmPassword"
                    type="password"
                    onChange={formik.handleChange}
                    className="form-control"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="confirmPassword" />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  style={{ backgroundColor: '#00a9f3', borderColor: '#00a9f3' }}
                >
                  Sign up
                </button>
                <h6 className="my-2">
                  Already registerd?
                  <Link to="../signin">
                    <a> Sign in here</a>
                  </Link>
                </h6>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
