import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteUser } from '../../../apis/app.service';
import { useUsersPage } from '../../../store/UsersProvider';

export default function ConfirmUserDelete() {
  const { institutionHead } = useUsersPage();

  const { data } = institutionHead;
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const handleDelete = (userId: string) => {
    mutation.mutate(userId);
  };

  return (
    <>
      <div
        className="modal fade"
        id="kt_modal_confirm_delete"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-900px">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Confirm Action</h2>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body py-lg-5 px-lg-5">
              Are you sure you want to delete{' '}
              <strong>{`${data.firstname} ${data.lastname}` ?? 'User'}?</strong>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => handleDelete(data.id ?? '')}
                data-bs-dismiss="modal"
                className="btn btn-lg btn-danger me-3"
              >
                {!mutation.isPending && (
                  <span className="indicator-label">Delete</span>
                )}
                {mutation.isPending && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
}
