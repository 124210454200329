import React from 'react';
import { DevicesProvider } from '../../store/DevicesProvider';
import DeviceWrapper from './components/DeviceWrapper';
import './core/styles.scss';

export default function DevicesPage() {
  return (
    <DevicesProvider>
      <DeviceWrapper />
    </DevicesProvider>
  );
}
