/* eslint-disable jsx-a11y/anchor-is-valid */
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
} from 'mui-datatables';
import { useQuery } from '@tanstack/react-query';

import { IInstitutionHead, IInstitutionHeadForm } from '../core/models';
import { SGetUsers } from '../../../apis/app.service';

import CreateEditUser from './CreateEditUser';
import ConfirmPasswordReset from './ConfirmPasswordReset';
import UpdatePassword from './UpdatePassword';
import ConfirmUserDelete from './ConfirmUserDelete';
import LoadingSpinner from '../../sharedcomponents/LoadingSpinner';
import { useUsersPage } from '../../../store/UsersProvider';
import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useAuth } from '../../../store/AuthProvider';
import ConfirmInstitutionActive from './ConfirmInstitutionActive';

export default function UsersWrapper() {
  const { institutionHead, setInstitutionHead } = useUsersPage();
  const { user } = useAuth();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['users'],
    queryFn: () =>
      SGetUsers(user!._id, {
        admin_Account: user!._id,
        role: 'institution_head',
      }),
  });

  const columns = [
    {
      name: 'user_Id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Credentials',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const data = tableMeta.tableData[tableMeta.rowIndex];
          return (
            <div>
              <div className="row">
                <p className="col-2">
                  <b>Name:</b>
                </p>
                <p className="col-10">{data.name}</p>
              </div>
              <div className="row">
                <p className="col-2">
                  <b>Email:</b>
                </p>
                <p className="col-10">{data.email}</p>
              </div>
              <div className="row">
                <p className="col-2">
                  <b>Phone:</b>
                </p>
                <p className="col-10">{data.phoneNumber}</p>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'institution',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          return <span>{value?.name}</span>;
        },
      },
    },
    {
      name: 'active',
      label: 'Active',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: boolean | undefined, tableMeta: any) => {
          const row = tableMeta.tableData[tableMeta.rowIndex];
          return (
            <div
              onClick={() => {
                let user = {
                  ...institutionHead,
                  data: {
                    ...institutionHead.data,
                    id: row._id,
                    active: row.active,
                  },
                };
                setInstitutionHead(user);
              }}
            >
              <div
                className="form-check form-check-solid form-switch"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_confirm_active"
              >
                <input
                  className="form-check-input"
                  style={{
                    height: '20px',
                    width: '35px',
                  }}
                  type="checkbox"
                  checked={value}
                />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'position',
      label: 'Position',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          return <span>{value}</span>;
        },
      },
    },
    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const row = tableMeta.tableData[tableMeta.rowIndex];

          const data: IInstitutionHeadForm = {
            id: row._id,
            email: row.email,
            organisationName: row.institution?.name || '',
            firstname: row.name.split(' ')[0],
            lastname: row.name.split(' ')[1],
            position: row.position || '',
            phoneNumber: row.phoneNumber.slice(4),
            type: row.type,
            role: row.role,
            department: row.department,
            permissions: row.institution
              ? row.institution.permissions
              : {
                  interviews: true,
                  devices: false,
                  reports: true,
                },
          };
          return (
            <>
              {/* begin::Menu */}
              <div
                className="d-flex flex-row align-content-center w-125px py-4"
                data-kt-menu="true"
              >
                {/* begin::Menu item */}
                <span
                  className="btn-icon-default pe-5 icon-color"
                  onClick={() => {
                    setInstitutionHead({ data, action: 'edit' });
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_user"
                >
                  <i className="fas fa-edit btn-icon-default" title="Edit"></i>
                </span>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <span
                  className="btn-icon-default pe-5 icon-color"
                  onClick={() => {
                    setInstitutionHead({ data, action: 'delete' });
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_confirm_delete"
                >
                  <i
                    className="fas fa-trash btn-icon-default"
                    title="Delete"
                  ></i>
                </span>
                <span
                  className="btn-icon-default pe-5 icon-color"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_confirm_reset"
                  onClick={() => {
                    setInstitutionHead({ data, action: 'change-password' });
                  }}
                >
                  <i
                    className="fas fa-key btn-icon-default"
                    title="password reset"
                  ></i>
                </span>
                {/* end::Menu item */}
              </div>
              {/* end::Menu */}
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    caseSensitive: false,
    filterType: 'dropdown',
    viewColumns: false,
    responsive: 'standard',
    jumpToPage: true,
    downloadOptions: { filename: 'Users.csv', separator: ',' },
    elevation: 1,
    customToolbar: () => {
      return (
        <AppButton
          type="button"
          btnType="success"
          data-bs-toggle="modal"
          width="160px"
          data-bs-target="#kt_modal_create_user"
          onClick={() =>
            setInstitutionHead({
              action: 'create',
              data: {
                email: '',
                organisationName: '',
                firstname: '',
                lastname: '',
                position: '',
                phoneNumber: '',
                type: 'external',
                role: 'institution_head',
                permissions: {
                  interviews: true,
                  devices: false,
                  reports: true,
                },
              },
            })
          }
        >
          <i className="fa-solid fa-plus fa-lg me-2"></i>
          <span>Add Institution</span>
        </AppButton>
      );
    },
    customSearchRender: (searchText, handleSearch) => {
      return (
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid ps-14 mt-3"
              style={{ width: '250px' }}
              placeholder="Search user"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
      );
    },
  };
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <div className="d-flex justify-center align-content-center">
        <p>Oops! Something Went Wrong! Try Again!!!</p>
        <p>{error.message}</p>
      </div>
    );
  }
  return (
    <div className="users">
      <MUIDataTable
        title="Institutions"
        data={data}
        columns={columns}
        options={options}
      />
      {/* Modals */}
      <CreateEditUser />
      <ConfirmUserDelete />
      <UpdatePassword />
      <ConfirmPasswordReset />
      <ConfirmInstitutionActive />
    </div>
  );
}
