import SideBar from './components/SideBar/SideBar';
import Footer from './components/Footer/Footer';

export default function MasterLayout() {
  return (
    <div>
      <SideBar />
      <Footer />
    </div>
  );
}
