import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import AuthPage from '../pages/Auth/AuthPage';

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthPage />} />
      <Route path="/" element={<AuthPage />} />
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  );
}
