import { createContext, ReactNode, useContext, useState } from 'react';

import { ReportsContextType } from './core/models';
import { IReport } from '../pages/Reports/core/models';

const ReportsContext = createContext<ReportsContextType | null>(null);

function ReportsProvider({ children }: { children: ReactNode }) {
  const [report, setReport] = useState<IReport>({
    action: 'create',
    data: {
      name: '',
      type: 'monthly',
      recipientIds: [],
      startDate: '',
      endDate: '',
      report: '',
    },
  });

  const [reportUrl, setReportUrl] = useState('');

  return (
    <ReportsContext.Provider
      value={{
        report,
        setReport,
        reportUrl,
        setReportUrl,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
}

function useReportsPage() {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error('useReportsPage must be used within a ReportsProvider');
  }
  return context;
}

export { ReportsProvider, useReportsPage };
