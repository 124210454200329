import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { DevicesContextType } from './core/models';
import { IDevice } from '../pages/Devices/core/models';
import { useQueryClient } from '@tanstack/react-query';

function generateNextDeviceId(
  devices: any,
  generationPrefix: string,
  cat: string
): string {
  // Extract the number part of the device_id across all devices

  const allDeviceNumbers = devices
    .filter((device: { device_id: string }) => {
      if (cat === 'field') {
        return device.device_id.startsWith('S');
      }
      if (cat === 'test') {
        return device.device_id.startsWith('T');
      }
      return false;
    })
    .map((device: { device_id: string }) => {
      const parts = device.device_id.split('-');
      return parseInt(parts[1], 10); // Get the number part and convert it to an integer
    })
    .filter((num: number) => !isNaN(num)); // Filter out any invalid numbers just in case

  // Find the highest device number across all generations
  const highestDeviceNumber = allDeviceNumbers.reduce(
    (max: number, current: number) => (current > max ? current : max),
    0
  );

  // Increment the highest number and return the new device_id with the new prefix
  const nextDeviceNumber = (highestDeviceNumber + 1)
    .toString()
    .padStart(3, '0'); // Ensure 3 digits format
  return `${generationPrefix}-${nextDeviceNumber}`;
}

function deviceTag(category: string, gen: string) {
  let cat = 'S';
  if (category === 'field') {
    cat = 'S';
  }
  if (category === 'test') {
    cat = 'T';
  }
  return `${cat}${gen}`;
}
const DevicesContext = createContext<DevicesContextType | null>(null);

const DevicesProvider = ({ children }: { children: React.ReactNode }) => {
  const [device, setDevice] = useState<IDevice>({
    action: 'create',
    data: {
      id: '',
      category: 'field',
      gen: '4',
      device_id: 'S4-001',
      active: true,
      user_Id: [],
      placement: '',
      device_imei: '',
      phoneNumber: '',
      deploymentDate: '',
      state: '',
    },
  });

  const queryClient = useQueryClient();

  const nextId = useMemo(() => {
    const { category, gen } = device.data;
    const tag = deviceTag(category, gen);

    if (device.action === 'create') {
      const queryData = queryClient.getQueryData(['devices']);
      if (queryData) {
        return generateNextDeviceId(queryData, tag, category);
      }
    }
    if (device.action === 'edit') {
      return `${tag}-${device.data.device_id?.split('-')[1]}`;
    }
    return device.data.device_id;
  }, [device.action, device.data, queryClient]);

  useEffect(() => {
    setDevice((device) => {
      return { ...device, data: { ...device.data, device_id: nextId } };
    });
  }, [nextId]);

  return (
    <DevicesContext.Provider value={{ device, setDevice }}>
      {children}
    </DevicesContext.Provider>
  );
};

function useDevicesPage() {
  const context = useContext(DevicesContext);
  if (!context) {
    throw new Error('useDevicesPage must be used with in a Device Provider');
  }
  return context;
}

export { DevicesProvider, useDevicesPage };
