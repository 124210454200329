import React, { createContext, useContext, ReactNode } from 'react';
import { SettingContextType } from './core/models';

// Create Context
const SettingContext = createContext<SettingContextType | undefined>(undefined);

// Create a Provider Component
function SettingProvider({ children }: { children: ReactNode }) {
  return (
    <SettingContext.Provider value={{}}>{children}</SettingContext.Provider>
  );
}

// Custom hook for using the context
function useSettingPage() {
  const context = useContext(SettingContext);
  if (!context) {
    throw new Error('useSettingPage must be used within an SettingProvider');
  }
  return context;
}

export { SettingProvider, useSettingPage };
