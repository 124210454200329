import { IDevice } from '../../pages/Devices/core/models';
import { IInterview } from '../../pages/Interviews/core/models';
import { IReport } from '../../pages/Reports/core/models';
import {
  IDepartmentHead,
  IInstitutionHead,
} from '../../pages/Users/core/models';

export const AUTH_LOCAL_KEY = 'talktosema_user';
export const AUTH_LOCAL_USER = 'talktosema_user_body';

export interface AuthContextType {
  token: string | null;
  user: User | null;
  setUser: (loggedInUser: User) => void;
  login: (token: string, loggedInUser: User) => void;
  logout: () => void;
}

export interface UsersContextType {
  departmentHead: IDepartmentHead;
  setDepartmentHead: (user: IDepartmentHead) => void;
  institutionHead: IInstitutionHead;
  setInstitutionHead: (user: IInstitutionHead) => void;
  newPassword: string;
  setNewPassword: (user: string) => void;
  openCollapse: string | null;
  handleToggle: (collapseId: string | null) => void;
}

export interface ReportsContextType {
  report: IReport;
  setReport: (report: IReport) => void;
  reportUrl: string;
  setReportUrl: (reportURL: string) => void;
}

export interface InterviewsContextType {
  interview: IInterview;
  setInterview: (interview: IInterview) => void;
  interviewUrl: string;
  setInterviewUrl: (interviewURL: string) => void;
}

export interface DevicesContextType {
  device: IDevice;
  setDevice: (device: any) => void;
}
export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phoneNumber: string;
}

export interface SettingContextType {}
