import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { useAuth } from '../store/AuthProvider';

export default function AppRoutes() {
  const { token } = useAuth();

  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {token ? <PrivateRoutes /> : <PublicRoutes />}
      </BrowserRouter>
    </>
  );
}
