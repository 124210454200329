import React from 'react';
import toast from 'react-hot-toast';
import { updateUserPassword } from '../../../apis/app.service';
import { useMutation } from '@tanstack/react-query';
import { useUsersPage } from '../../../store/UsersProvider';

function generatePassword(length: number) {
  // define a string of possible characters for the password
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';

  let password = '';
  for (let i = 0; i < length; i++) {
    // get a random character from the possible characters
    const randomIndex = Math.floor(Math.random() * characters.length);
    const randomChar = characters[randomIndex];

    // add the random character to the password
    password += randomChar;
  }

  return password;
}

export default function ConfirmPasswordReset() {
  const { institutionHead, setNewPassword } = useUsersPage();

  const { data } = institutionHead;
  const mutation = useMutation({
    mutationFn: updateUserPassword,
    onSuccess: ({ message }) => {
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });
  const resetPassword = () => {
    const password = generatePassword(14);
    setNewPassword(password);
    mutation.mutate({ userId: data.id!, password });
  };

  return (
    <>
      <div
        className="modal fade"
        id="kt_modal_confirm_reset"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered mw-500px">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Reset password</h2>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body pb-1">
              <div>
                <p>
                  Are you sure you want to reset the password for{' '}
                  {`${data.firstname} ${data.lastname}` ?? 'User'}?
                </p>
              </div>
            </div>
            <div className="modal-footer py-2">
              <button
                type="button"
                className="btn btn-lg btn-secondary me-3"
                data-bs-dismiss="modal"
              >
                <span className="indicator-label">Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-lg btn-success me-3"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_update_password"
                onClick={resetPassword}
              >
                <span className="indicator-label">Reset Password</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
