import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUpdateDevice } from '../../../apis/app.service';

import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useDevicesPage } from '../../../store/DevicesProvider';
import { IDevice, IDevicePayload } from '../core/models';

export default function ConfirmDeviceActive() {
  const { device } = useDevicesPage();
  const { data } = device;

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: SUpdateDevice,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['devices'],
      });
      const { message } = data;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const handleUpdate = (deviceId: string, body: any) => {
    mutation.mutate({ deviceId, body });
  };

  return (
    <>
      <div
        className="modal fade"
        id="kt_modal_confirm_active"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-900px">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Confirm Action</h2>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              Are you sure you want to turn device with ID
              <strong>{` ${data.device_id}`}</strong>
              {data.active ? ' Off.' : ' On.'}
            </div>
            <div className="modal-footer">
              <AppButton
                btnType="success"
                onClick={() => handleUpdate(data.id!, { active: !data.active })}
                data-bs-dismiss="modal"
              >
                {!mutation.isPending && (
                  <span className="indicator-label">Save</span>
                )}
                {mutation.isPending && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
