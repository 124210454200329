export const formatDate = (inputDate: string) => {
  // Parse the input date string into a Date object
  if (inputDate) {
    const date = new Date(inputDate);

    // Extract the day, month, and year from the Date object
    const day = String(date.getUTCDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and ensure two digits
    const year = date.getUTCFullYear();

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  }
  return '';
};
