import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { SettingProvider } from '../../store/SettingsProvider';

type MenuItem = Required<MenuProps>['items'][number];

//TODO: Menu display based on user role
const items: MenuItem[] = [
  {
    label: 'Profile',
    key: 'profile',
    icon: <UserOutlined />,
  },
];

export default function SettingsPage() {
  const [current, setCurrent] = useState('profile');
  const location = useLocation();
  const navigate = useNavigate();

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
    setCurrent(e.key);
  };

  useEffect(() => {
    const page = location.pathname.split('/')[2];
    setCurrent(page);
  }, [location]);

  return (
    <SettingProvider>
      <div className="container-lg">
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
        <div>
          <Outlet />
        </div>
      </div>
    </SettingProvider>
  );
}
