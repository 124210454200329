import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import MasterLayout from '../layout/MasterLayout';
import UsersPage from '../pages/Users/UsersPage';
import InterviewsPage from '../pages/Interviews/InterviewsPage';
import ReportsPage from '../pages/Reports/ReportsPage';
import ErrorPage from '../pages/Error/ErrorPage';
import AuthPage from '../pages/Auth/AuthPage';
import SettingsPage from '../pages/Settings/SettingsPage';
import Profile from '../pages/Settings/components/Profile/Profile';
import DevicesPage from '../pages/Devices/DevicesPage';

export default function PrivateRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route path="users" element={<UsersPage />} />
        <Route path="interviews" element={<InterviewsPage />} />
        <Route path="devices" element={<DevicesPage />} />
        <Route path="reports" element={<ReportsPage />} />
        <Route path="settings/" element={<SettingsPage />}>
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route index element={<Navigate to="/users" />} />
      </Route>
      <Route path="/auth/*" element={<AuthPage />} />
      <Route path="*" element={<ErrorPage goToPage="/users" />} />
    </Routes>
  );
}
