import React, { useRef } from 'react';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import toast from 'react-hot-toast';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { Formik, FormikValues, Field, ErrorMessage, Form } from 'formik';
import { IInterviewForm } from '../core/models';

import { createInterview, updateInterview } from '../../../apis/app.service';
import { useInterviewsPage } from '../../../store/InterviewsProvider';
import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useAuth } from '../../../store/AuthProvider';

const createInterviewSchema = Yup.object({
  name: Yup.string().required().label('Interview Name'),
  duration: Yup.string().nullable().required().label('Period'),
  interview: Yup.mixed().required().label('Interview File'),
});

export default function CreateEditInterview() {
  const { interview } = useInterviewsPage();
  const { action, data } = interview;
  const { user } = useAuth();

  const fileRef = useRef<HTMLInputElement | null>(null);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: createInterview,
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({ queryKey: ['interviews'] });
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const mutationUpdate = useMutation({
    mutationFn: updateInterview,
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({ queryKey: ['interviews'] });
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const submit = (values: IInterviewForm, actions: FormikValues) => {
    const { name, duration, interview } = values;
    const [month, year] = duration.split('-');
    const formData = new FormData();
    const startDate = moment(duration, 'MMMM-YYYY')
      .startOf('month')
      .format('DD/MM/YYYY');
    const endDate = moment(duration, 'MMMM-YYYY')
      .endOf('month')
      .format('DD/MM/YYYY');

    // FormData only accepts string or blob types
    formData.append('name', name);
    formData.append('month', month);
    formData.append('year', year);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('interview', interview);

    if (action === 'create') {
      formData.append('created_By', user!._id);
      formData.append('admin_Account', user!._id);
      mutation.mutate(formData);
    } else if (action === 'edit') {
      mutationUpdate.mutate({ interviewId: values.id!, body: formData });
    }
  };

  return (
    <div
      className="modal fade"
      id="kt_modal_create_interview"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: '900px' }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2>
              <span className="text-capitalize">{action}</span> Interview
            </h2>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                if (fileRef.current) {
                  fileRef.current.value = '';
                }
              }}
            ></button>
          </div>

          <div className="modal-body py-lg-10 px-lg-10">
            {/* start create form */}
            <div
              className="d-flex flex-column flex-xl-row flex-row-fluid"
              id="kt_modal_create_device"
            >
              {/* begin form body */}
              <div className="w-100 d-flex flex-column justify-center">
                <Formik
                  validationSchema={createInterviewSchema}
                  initialValues={data}
                  onSubmit={submit}
                  encType="multipart/form-data"
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="form">
                      {/* begin form body */}
                      <div
                        className="current"
                        data-kt-stepper-element="content"
                      >
                        <div className="w-100">
                          {/* begin Interview name */}
                          <div className="mb-4">
                            <label className="form-label fs-5">
                              <span className="required">Interview Name</span>
                              <i
                                className="fas fa-exclamation-circle ms-2"
                                data-bs-toggle="tooltip"
                                title="Specify Interview Name"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="e.g Institution client response March 2024"
                            />
                            <div className="text-danger">
                              <ErrorMessage name="name" />
                            </div>
                          </div>
                          {/* end Interview Name */}

                          {/* begin period row */}
                          <div className="mb-4">
                            {/* begin startDate */}
                            <div className="col">
                              <label className="form-label fs-5">
                                <span className="required">
                                  Interview Period
                                </span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Specify month"
                                ></i>
                              </label>

                              <DatePicker
                                value={
                                  formik.values.duration
                                    ? dayjs(formik.values.duration, 'MMMM-YYYY')
                                    : null
                                }
                                picker="month"
                                format={'MMMM-YYYY'}
                                style={{ width: '100%' }}
                                placement="bottomLeft"
                                onChange={(date, dateString) =>
                                  formik.setFieldValue('duration', dateString)
                                }
                              />

                              <div className="text-danger">
                                <ErrorMessage name="duration" />
                              </div>
                            </div>
                            {/* end startDate */}
                          </div>
                          {/* end period row */}

                          {/* begin upload file */}
                          <div className="mb-4">
                            <label className="form-label fs-5">
                              <span className="required">Upload File</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="upload Interview file"
                              ></i>
                            </label>

                            <input
                              type="file"
                              className="form-control"
                              name="interview"
                              accept=".csv"
                              onChange={(e: any) => {
                                const file: File = e.target.files[0] || '';
                                formik.setFieldValue('interview', file);
                              }}
                              ref={fileRef}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="interview" />
                            </div>
                          </div>
                          {/* end upload file */}
                        </div>
                      </div>

                      <div className="w-100 d-flex flex-row justify-content-center">
                        <AppButton btnType="success" type="submit">
                          Save
                        </AppButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* end form body */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
