import React from 'react';
import { UsersProvider } from '../../store/UsersProvider';
import UsersWrapper from './components/UsersWrapper';

import './core/styles.scss';

export default function UsersPage() {
  return (
    <div>
      <UsersProvider>
        <UsersWrapper />
      </UsersProvider>
    </div>
  );
}
