/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './css/footer.scss';

export default function Footer() {
  return (
    <div className="layout-footer py-4 d-flex flex-lg-column">
      <div className={`d-flex flex-column flex-md-row align-items-center justify-content-between`}>
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">{new Date().getFullYear()} &copy;</span>
          <a href="#" className="text-gray-800 text-hover-primary">
            SEMA
          </a>
        </div>
      </div>
    </div>
  );
}
