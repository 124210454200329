import { APICore } from './api.core';
import {
  IDepartmentHeadPayLoad,
  IInstitutionHeadPayload,
} from '../pages/Users/core/models';
import { SignInForm, SignUpForm } from '../pages/Auth/core/models';
import {
  IChangePassword,
  UserProfileUpdate,
} from '../pages/Settings/core/models';
import { IDevicePayload } from '../pages/Devices/core/models';

const api = new APICore();
// ================ begin auth endpoints ===================

export const SignIn = async (body: SignInForm) => {
  const response = await api.postAPI('/api/user/login', body);

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const SignUp = async (body: SignUpForm) => {
  const response = await api.postAPI('/api/user/signup', body);

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const ProfileUpdate = async (body: UserProfileUpdate) => {
  const response = await api.patchAPI('/api/user/updateProfile', body);

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const PasswordUpdate = async (body: IChangePassword) => {
  const response = await api.patchAPI('/api/user/updatePassword', body);

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const deleteAccount = async () => {
  const response = await api.deleteAPI('/api/user/deleteAccount');

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

// ================ end auth endpoints ===================

// ================ begin user endpoints ===================
export const SGetUsers = async (id: string, params?: any) => {
  const { status, message, data } = await api
    .getAPI(`/api/user/${id}/external`, { params })
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return data.docs;
};

export const SCreateUser = async (
  body: IInstitutionHeadPayload | IDepartmentHeadPayLoad
) => {
  const { status, message, data } = await api
    .postAPI('/api/user', body)
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const SUpdateUser = async (update: {
  userId: string;
  body: IInstitutionHeadPayload | IDepartmentHeadPayLoad;
}) => {
  const { status, message, data } = await api
    .patchAPI(`/api/user/${update.userId}`, update.body)
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return { message, data };
};
export const updateUserPassword = async (queryData: {
  userId: string;
  password: string;
}) => {
  const { status, message, data } = await api
    .patchAPI(`/api/user/userUpdatePassword`, queryData)
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const deleteUser = async (userId: string) => {
  const { status, message } = await api
    .deleteAPI(`/api/user/${userId}`)
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return { message };
};

// ================ end user endpoints ===================

// ================ begin interview endpoints ==================

export const getInterviews = async () => {
  const { status, message, data } = await api
    .getAPI('/api/interview')
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return data.docs;
};
export const createInterview = async (body: FormData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const { status, message, data } = await api
    .postAPI('/api/interview', body, config)
    .then((res) => res.data);
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const updateInterview = async (params: {
  interviewId: string;
  body: FormData;
}) => {
  const { interviewId, body } = params;
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const { status, message, data } = await api
    .patchAPI(`/api/interview/${interviewId}`, body, config)
    .then((res) => res.data);
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const deleteInterview = async (interviewId: string) => {
  const { status, message, data } = await api
    .deleteAPI(`/api/interview/${interviewId}`)
    .then((res) => res.data);
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

// ================ end interview endpoints ===================

// ================ begin device endpoints ==================

export const SCreateDevice = async (body: IDevicePayload) => {
  const { status, message, data } = await api
    .postAPI('/api/device', body)
    .then((res) => res.data);
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const SUpdateDevice = async (params: {
  deviceId: string;
  body: any;
}) => {
  const { status, message, data } = await api
    .patchAPI(`/api/device/${params.deviceId}`, params.body)
    .then((res) => res.data);
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const getDevices = async (query?: any) => {
  const { status, message, data } = await api
    .getAPI('/api/device', { params: query })
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return data.docs;
};

export const SDeleteDevice = async (deviceId: string) => {
  const { status, message, data } = await api
    .deleteAPI(`/api/device/${deviceId}`)
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return { message };
};
// ================ end device endpoints ==================

// ================ begin report endpoints ===================
export const getReportData = async (query?: any) => {
  const { status, message, data } = await api
    .getAPI('/api/report', { params: query })
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return data.docs;
};

export const createNewReport = async (body: FormData) => {
  const { status, message, data } = await api
    .postAPI('/api/report', body, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const updateReport = async (params: {
  reportId: string;
  body: FormData;
}) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const { status, message, data } = await api
    .patchAPI(`/api/report/${params.reportId}`, params.body, config)
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const deleteReport = async (reportId: string) => {
  const { status, message } = await api
    .deleteAPI(`/api/report/${reportId}`)
    .then((res) => res.data);
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message };
};

// ================ end report endpoints ===================
