import { createContext, useContext, useState } from 'react';
import { InterviewsContextType } from './core/models';
import { IInterview } from '../pages/Interviews/core/models';

const InterviewsContext = createContext<InterviewsContextType | null>(null);

function InterviewsProvider({ children }: { children: React.ReactNode }) {
  const [interview, setInterview] = useState<IInterview>({
    action: 'create',
    data: {
      name: '',
      duration: '',
      interview: '',
    },
  });
  const [interviewUrl, setInterviewUrl] = useState('');

  return (
    <InterviewsContext.Provider
      value={{ interview, setInterview, interviewUrl, setInterviewUrl }}
    >
      {children}
    </InterviewsContext.Provider>
  );
}

function useInterviewsPage() {
  const context = useContext(InterviewsContext);

  if (!context) {
    throw new Error(
      'useInterviewsPage should be used with in a InterviewsProvider'
    );
  }

  return context;
}

export { InterviewsProvider, useInterviewsPage };
