// primary Submit/Save #007bff
// secondary Cancel/Back #6c757d
// success Add #ff9933
// danger Delete #dc3545
// warning Proceed with Caution #ffc107
// info More Info #17a2b8

import React from 'react';

import './core/style.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string;
  btnType?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
  children: React.ReactNode;
}

export default function AppButton({
  children,
  btnType = 'primary',
  width = '120px',
  ...rest
}: Props) {
  return (
    <button
      type="button"
      className={`btn mb-2 mb-md-0 btn-block appbtn-${btnType} rounded-4`}
      style={{ width }}
      {...rest}
    >
      {children}
    </button>
  );
}
