/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useUsersPage } from '../../../store/UsersProvider';

export default function UpdatePassword() {
  const { newPassword } = useUsersPage();
  const [fieldType, setFieldType] = useState('password');

  return (
    <>
      <div
        className="modal fade"
        id="kt_modal_update_password"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered mw-500px">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Password successfully reset</h2>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body pb-2">
              <div className="row g-3">
                <div className="col-7">
                  <input
                    type={fieldType}
                    className="form-control border-0 disabled input-lg"
                    value={newPassword}
                    aria-label="First name"
                    readOnly
                  />
                </div>
                <div
                  className="col-1"
                  onClick={() =>
                    setFieldType((value) =>
                      value === 'text' ? 'password' : 'text'
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                  </svg>
                </div>
                <div className="col-4">
                  <a
                    href="#"
                    className="pe-auto"
                    onClick={() => {
                      navigator.clipboard.writeText(newPassword);
                    }}
                  >
                    <h3 className="text-primary">copy password</h3>
                  </a>
                </div>
              </div>
              <div>
                <p>Ensure to copy this password before closing this dialog.</p>
              </div>
            </div>
            <div className="modal-footer py-2">
              <button
                type="button"
                className="btn btn-lg btn-primary me-3"
                data-bs-dismiss="modal"
                onClick={() => setFieldType('password')}
              >
                <span className="indicator-label">close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
